body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-radio-wrapper .ant-radio-inner {
  width: 22px;
  height: 22px;
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--main-orange);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--main-orange);
  background-color: var(--main-orange);
}

.ant-message.ant-message-top {
  top: 70px !important;
}

.ant-message-custom-content.ant-message-error {
  display: flex;
  align-items: center;
}

.ant-input[disabled] {
  color: var(--gray-300);
}

.ant-picker .ant-picker-input >input[disabled] {
  color: var(--gray-300);
}
